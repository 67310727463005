<template>
  <div class="news_portal">
    <Welcome />
    <div class="news">
      <div class="news_wrapper" data-testId="news-privacy-dossier">
        <div class="news_wrapper__slider">
          <div class="news_wrapper__container">
            <h3 class="news_wrapper__title">{{ $t("news.privacy.title") }}</h3>
            <p class="news_wrapper__desc">
              {{ $t("news.privacy.desc") }}
            </p>
          </div>
          <div class="news_wrapper__slider_wrapper">
            <DgSlider
              :dataBlocks="privacyNewsList"
              :blockSize="privacyDossierBlockSize"
              :isLoading="fetchingPrivacyNewsList"
              @pos="onPrivacyDossierSlideChange"
            />
          </div>
        </div>
      </div>
      <div class="news_wrapper--gray">
        <div class="news_wrapper__container">
          <h3>{{ $t("news.info_block.title") }}</h3>
          <p>
            {{ $t("news.info_block.desc") }}
          </p>
        </div>
      </div>
    </div>
    <Menu :menuList="categoryList" :activeMenu="activeCategory" />
    <NewsList :data="newsList" :isLoading="fetchingMagazineNewsList" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Structural } from "@/dgui-customer-components/";

export default {
  name: "News-Dashboard",
  data() {
    return {
      privacyNewsList: [],
      newsList: [],
      fetchingPrivacyNewsList: false,
      fetchingMagazineNewsList: false,
      nextPageForMagazine: "",
      nextPageForPrivacy: "",
      privacyDossierBlockSize: 320,
      magazineApiCancelSource: null,
    };
  },
  computed: {
    defaultCategoryId() {
      const { categoryList = [] } = this.hubspotDetails;
      return categoryList[0];
    },
    categoryList() {
      const { categoryList, tKey } = this.hubspotDetails;
      return categoryList.map((topicId, index) => {
        return {
          id: topicId,
          title: this.$t(`news.${tKey}[${index}]`),
        };
      });
    },
    activeCategory() {
      return this.$route.query.category || this.defaultCategoryId;
    },
    ...mapGetters(["hubspotDetails", "companyData"]),
  },
  watch: {
    "$route.query.category"() {
      // reset news list to empty array
      this.nextPageForMagazine = "";
      this.newsList = [];
      this.$nextTick(() => {
        if (this.magazineApiCancelSource) {
          this.magazineApiCancelSource.cancel();
        }
        this.fetchMagazineNewsList();
      });
    },
  },
  components: {
    DgSlider: Structural.DgSlider,
    Welcome: () => import("../components/Welcome.vue"),
    Menu: () => import("../components/Menu.vue"),
    NewsList: () => import("../components/NewsList.vue"),
  },
  mounted() {
    if (this.companyData) {
      this.fetchAllNews();
    }
  },
  destroyed() {
    this.removeScrollBindings();
  },
  methods: {
    fetchAllNews() {
      this.newsList = [];
      this.privacyNewsList = [];
      this.fetchPrivacyNewsList();
      this.fetchMagazineNewsList();
    },
    async fetchPrivacyNewsList() {
      this.fetchingPrivacyNewsList = true;
      const { privacyDossierTopicId } = this.hubspotDetails;
      let queryParams = `content_group_id=${privacyDossierTopicId}`;
      if (this.nextPageForPrivacy) {
        queryParams += `&after=${this.nextPageForPrivacy}`;
      }
      try {
        const {
          data: { results = [], paging = {} },
        } = await this.$http.get(`/news-posts?${queryParams}`);
        if (results.length > 0) {
          const publishedNews = results.filter(this.filterPublishedNews);
          this.privacyNewsList = [...this.privacyNewsList, ...publishedNews];
        }
        this.nextPageForPrivacy = paging?.next?.after || "";
      } catch (err) {
        this.apiCatch(err);
      } finally {
        this.fetchingPrivacyNewsList = false;
      }
    },
    async fetchMagazineNewsList() {
      this.fetchingMagazineNewsList = true;
      const { magazineTopicId } = this.hubspotDetails;
      let queryParams = `content_group_id=${magazineTopicId}&topic_id=${this.activeCategory}`;
      if (this.nextPageForMagazine) {
        queryParams += `&after=${this.nextPageForMagazine}`;
      }
      this.magazineApiCancelSource = this.$http.CancelToken.source();
      try {
        const {
          data: { results = [], paging = {} },
        } = await this.$http.get(`/news-posts?${queryParams}`, {
          cancelToken: this.magazineApiCancelSource.token,
        });
        if (results.length > 0) {
          const publishedNews = results.filter(this.filterPublishedNews);
          this.newsList = [...this.newsList, ...publishedNews];
        }
        this.nextPageForMagazine = paging?.next?.after || "";
        if (this.nextPageForMagazine != "") {
          this.addScrollBindings();
        } else {
          this.removeScrollBindings();
        }
      } catch (err) {
        this.apiCatch(err);
      } finally {
        this.fetchingMagazineNewsList = false;
        this.magazineApiCancelSource = null;
      }
    },
    addScrollBindings() {
      window.addEventListener("scroll", this.onWindowScroll);
    },
    onWindowScroll() {
      if (this.fetchingMagazineNewsList) return;
      const htmlElement = document.querySelector("html");
      /**
       * This calculation is to check if user scrolled to the bottom of the page
       * And then fetch the next list of articles if we have anything in the next page
       */
      const hasReachedBottom = htmlElement.scrollTop > htmlElement.scrollHeight - 500;

      if (this.nextPageForMagazine && hasReachedBottom) {
        // fetch next page
        this.fetchMagazineNewsList();
      }
    },
    removeScrollBindings() {
      window.removeEventListener("scroll", this.onWindowScroll);
    },
    onPrivacyDossierSlideChange(slidePos) {
      const currentSlidePos = Math.abs(slidePos);
      const isAtLastTwoSlides = currentSlidePos >= this.privacyDossierBlockSize * (this.privacyNewsList.length - 2);
      if (this.nextPageForPrivacy && isAtLastTwoSlides) {
        this.fetchPrivacyNewsList();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.news_portal {
  width: 100%;
  overflow: auto;
}
.news {
  width: 100%;
  background: rgba(0, 51, 73, 1);

  .news_wrapper--gray {
    width: 100%;
    background: #dfe4e9;
    padding-top: 50px;
    padding-bottom: 70px;
    padding-left: 20px;
    padding-right: 20px;

    .news_wrapper__container {
      max-width: 1440px;
      margin: auto;
    }
  }

  .news_wrapper {
    max-width: 1440px;
    margin: 0 auto;

    .news_wrapper__slider {
      display: flex;
      max-width: 1440px;
      margin: auto;
      margin-top: 13px;
      margin-left: 20px;

      @media screen and (max-width: 960px) {
        flex-direction: column-reverse;
      }

      .news_wrapper__slider_wrapper {
        width: 50%;
        flex: 1;
        overflow: hidden;
        min-width: 470px;
        max-width: 60%;
        @media screen and (max-width: 960px) {
          max-width: none;
        }
      }

      .news_wrapper__container {
        color: #fff;
        padding-right: 24px;
        width: 40%;
        display: flex;
        flex-direction: column;
        align-self: center;

        .news_wrapper__title {
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 32px;
          position: relative;
          &:before {
            content: "";
            position: absolute;
            height: 4px;
            left: 0;
            width: 30px;
            bottom: -12px;
            background: #cb333b;
          }
        }
        .news_wrapper__desc {
          padding-top: 16px;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          @media screen and (max-width: 960px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
